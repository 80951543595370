import * as React from 'react';
import { useContext,useState,useEffect } from 'react';

import style from './style.scss';
import { ContextoModales } from './../../contextos/modales';

export default function Modal(props:any){
    const contextoModales = useContext(ContextoModales);
    function cerrar(e){
        e.preventDefault();
        contextoModales.cerrarModal();
    }
    return (
        <div className={(contextoModales.togle && contextoModales.id == props.id ? `${style.modal}` : `${style.modal} ${style.cerrado}`)}>
            <div className={style.carta}>
                <div className={style.botonera}><button onClick={(e)=>cerrar(e)}><i className="fas fa-times"></i></button></div>
                <div className={style.contenido}>{props.children}</div>
            </div>
        </div>
    )
}