import * as React from 'react';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useState, useContext} from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import style from './style.scss';
import foto from '../../assets/estudios.jpg';




export default function Estudios(props:any){
    useEffect(()=>{
        
    })
    return (
        <React.Fragment>
            <div className={style.foto}>
                <img src={foto} alt="Persona"/>
                <div className={style.titulo}>
                    <h1>Estudios</h1>
                    <p>Experiencia Escolar y Universitaria</p>
                </div>
            </div>
            <div className={style.contenido}>
                <div className={style.carta}>
                    <h1>ESCOLARES</h1>
                    <ul>
                        <li>
                            <h4>Primaria</h4>
                            <p>Escuela del Valle / Instituto Maria Auxiliadora 2000-2006</p>
                        </li>
                        <li>
                            <h4>Secundario</h4>
                            <p>Instituto Maria Auxiliadora / CEM9 2007-2011</p>
                        </li>
                    </ul>
                </div>
                <div className={style.carta}>
                    <h1>UNIVERSITARIOS</h1>
                    <ul>
                        <li>
                            <h4>Diseño Multimedial</h4>
                            <p>Universidad Nacional de la Plata 2012-2016</p>
                        </li>
                        <li>
                            <h4>Ing. en sistemas de Informacion</h4>
                            <p>Universidad Nacional del Sur 2017-1019</p>
                        </li>
                    </ul>
                </div>
                <div className={style.carta}>
                    <h1>EXTRAS</h1>
                    <ul>
                        <li>
                            <h4>Taller de Historieta</h4>
                            <p>casa de la cultura Gral. Roca(Prof. Chelo Candia) 2010</p>
                        </li>
                        <li>
                            <h4>Taller de UX</h4>
                            <p>IxDA la plata 2016</p>
                        </li>
                        <li>
                            <h4>Game Jam</h4>
                            <p>La plata 2012</p>
                        </li>
                        <li>
                            <h4>Blackthornprod GAME JAM #1</h4>
                            <p>itch.io 2018</p>
                        </li>
                    </ul>
                </div>
            </div>
            <ReactTooltip />
        </React.Fragment>
    )
}