import * as React from 'react';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useState, useContext} from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import style from './style.scss';
import foto from '../../assets/IMG_20191015_140932_384.jpg';




export default function Home(props:any){
    const fecha = moment("08/04/1993").fromNow(true).split(' ');
    useEffect(()=>{
        
    })
    return (
        <React.Fragment>
            <div className={style.foto}>
                <img src={foto} alt="Persona"/>
                <div className={style.titulo}>
                    <h1>Carlos Adolfo Fernandez Perez</h1>
                    <p>Diseño + Desarrollo</p>
                </div>
                <div className={style.redes}>
                    <a href="https://www.instagram.com/grrcodebar/"><i className="fab fa-instagram"></i></a>
                    <a href="https://twitter.com/grrcodebar"><i className="fab fa-twitter"></i></a>
                    <a href="http://gitlab.com/Charliegoree"><i className="fab fa-gitlab"></i></a>
                </div>
            </div>
            <div className={style.contenido}>
                <div className={style.carta}>
                    <h1>DATOS</h1>
                    <ul>
                        <li data-tip="Fecha de Nacimiento"><i className="fas fa-birthday-cake"></i> <span>04/08/93</span></li>
                        <li data-tip="Edad (si, esta en tiempo real)"><i className="fas fa-user-clock"></i> <span>{fecha[0]} años</span></li>
                        <li data-tip="Domicilio Actual"><i className="fas fa-home"></i><span>Bahia Blanca - Buenos Aires - Argentina</span></li>
                        <li data-tip="Nacionalidad"><i className="fas fa-flag"></i> <span>Argentina</span></li>
                        <li data-tip="Documento de Identidad"><i className="fas fa-passport"></i> <span>37359352</span></li>
                    </ul>
                </div>
                <div className={style.carta}>
                    <h1>SOBRE MI</h1>
                    <p>
                    Soy una persona emprendedora, entusiasta por el conocimiento y autodidacta, me destaco como Desarrollador Front-End, busco tener nuevas experiencias, conocer gente que me aporte mucho conocimiento en las áreas donde me desenvuelvo, Me considero alguien comunicativo, que aporta muchas ideas y siempre atento al desarrollo de las soluciones hacia los problemas que se van presentando y mi objetivo profesional es el aprender, conocer gente capaz y trabajar en las áreas de desarrollo web/híbrido.
                    </p>
                    <h3>Hobbies</h3>
                    <p>
                    En mi tiempo libre me gusta aprender, me paso la mayor parte del tiempo leyendo o acostumbro a probar lo nuevo mientras estudio su documentación o leo manuales, amante de las tecnologías web, cuando puedo desarrollo pequeños juegos a modo de experimentos o dibujo mientras tomo café...
                    </p>
                </div>
            </div>
            <ReactTooltip />
        </React.Fragment>
    )
}