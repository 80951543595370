import * as React from 'react';
import { Component } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Rutas } from './app/rutas';


import style from './app/style.scss';
import Menu from './app/componentes/menu';
import { ProveedorModales } from './app/contextos/modales';

class App extends Component<{},{}>{
  render() {
    return (
          <div className={style.base}>
            <ProveedorModales>
              <BrowserRouter>
                  <Menu />
                  <Rutas />
              </BrowserRouter>
            </ProveedorModales>
          </div>
    )
  }
}

render(<App />, document.getElementById('root'));
