import * as React from 'react';
import { useEffect,useState } from 'react';
import { NavLink } from 'react-router-dom';

import style from './style.scss';
import logo from '../../assets/logo.png';

export default function Menu(props:any){
    return (
        <nav className={style.menu}>
            <div className={style.titulo}>
                <img src={logo} alt="CV"/>
            </div>
            <NavLink to="/" exact={true} activeClassName={style.active}><i className="fas fa-user-alt"></i></NavLink>
            <NavLink to="/estudios" activeClassName={style.active}><i className="fas fa-graduation-cap"></i></NavLink>
            <NavLink to="/trabajo" activeClassName={style.active}><i className="fas fa-briefcase"></i></NavLink>
            <NavLink to="/contacto" activeClassName={style.active}><i className="fas fa-phone"></i></NavLink>
        </nav>
    )
}