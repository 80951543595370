import * as React from 'react';
import { Switch, Route } from "react-router-dom";
import Home from './vistas/home/index';
import Contacto from './vistas/contacto/index';
import Estudios from './vistas/estudios/index';
import Trabajo from './vistas/trabajo/index';

export function Rutas(props:any){
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/estudios" component={Estudios} />
            <Route exact path="/contacto" component={Contacto} />
            <Route exact path="/trabajo" component={Trabajo} />
        </Switch>
    );
}