import * as React from 'react';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useState, useContext} from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import style from './style.scss';
import foto from '../../assets/trabajo.jpg';
import Modal from '../../componentes/modal';
import { ContextoModales } from '../../contextos/modales';




export default function Trabajo(props:any){
    const contextoModales = useContext(ContextoModales);
    useEffect(()=>{
        
    })
    function abrirModal(e,id:string){
        e.preventDefault();
        contextoModales.abrirModal(id);
    }
    return (
        <React.Fragment>
            <div className={style.foto}>
                <img src={foto} alt="Persona"/>
                <div className={style.titulo}>
                    <h1>Trabajo</h1>
                    <p>projectos realizados y experiencia laboral</p>
                </div>
            </div>
            <div className={style.contenido}>
                <div className={style.carta}>
                    <h1>PROYECTOS</h1>
                    <ul>
                        <li onClick={(e)=>abrirModal(e,'cazelab')}> Cazelab</li>
                        <li onClick={(e)=>abrirModal(e,'joii')}> JOII</li>
                        <li onClick={(e)=>abrirModal(e,'alamofm')}> Alamo FM</li>
                        <li onClick={(e)=>abrirModal(e,'juegogj')}> Juego para GameJam</li>
                    </ul>
                </div>
                <div className={style.carta}>
                    <h1>FREELANCER</h1>
                    <ul>
                        <li onClick={(e)=>abrirModal(e,'frentepopular')}>Frente Popular</li>
                        <li onClick={(e)=>abrirModal(e,'sucesosnoticias')}>Sucesos Noticias</li>
                        <li onClick={(e)=>abrirModal(e,'ohmedios')}>Oh! Medios</li>
                        <li onClick={(e)=>abrirModal(e,'cozumel')}>Balneario Cozumel</li>
                        <li onClick={(e)=>abrirModal(e,'photozpot')}>Photozpot Studio</li>
                        <li onClick={(e)=>abrirModal(e,'cyr')}>C&R amoblamientos</li>
                    </ul>
                </div>
                <div className={style.carta}>
                    <h1>CAZELAB</h1>
                    <ul>
                        <li onClick={(e)=>abrirModal(e,'juegomobile')}>Juego Mobile</li>
                        <li onClick={(e)=>abrirModal(e,'oxigeno')}>Oxigeno crosstraining</li>
                        <li onClick={(e)=>abrirModal(e,'curriculum')}>Este Curriculum :)</li>
                    </ul>
                </div>
            </div>
            <ReactTooltip />
            <Modales />
        </React.Fragment>
    )
}

function Modales(){
    return (
        <React.Fragment>
            <Modal id="cazelab">
                <React.Fragment>
                    <h2>Cazelab</h2>
                    <p>EN CONSTRUCCIÓN - Proyecto Personal en el cual busco encontrar soluciones a problemas de los clientes, basado en la manera en la que trabajan para poder reducir los costos de tiempo y esfuerzo y a la vez aumentar los beneficios por medio de la tecnología y los medios multimediales.</p>
                    <a href="https://gitlab.com/charliegoree/cazelab-web"><i className="fab fa-gitlab"></i> Link a Gitlab</a>
                </React.Fragment>
            </Modal>
            <Modal id="joii">
                <React.Fragment>
                    <h2>Jornadas de integración de las ingenierías</h2>
                    <p>En este proyecto trabaje en el área de Difusión, desarrollando el cambio de imagen de las jornadas y en el desarrollo del sistema de administración de las jornadas desde el lado del front-end realizando un cliente para los participantes y otro para los organizadores.
                    </p>
                    <a href="https://gitlab.com/nahuelmorata/joiireact">Link a Gitlab</a>
                    <a href="https://drive.google.com/open?id=1ymw1drfJKJ4L574Gv4f_YLISTg51ufBm"><i className="fas fa-file-pdf"></i> Guías Visuales Realizadas para las JOII</a>
                    <a href="https://www.facebook.com/joii.Bahia"><i className="fab fa-facebook"></i> Link al facebook de las JOII</a>
                </React.Fragment>
            </Modal>
            <Modal id="alamofm">
                <React.Fragment>
                    <h2>ALAMO FM</h2>
                    <p>implementación del sistema de reproducción online para la radio alamofm de gral roca.y la APP para android se encuentra en desarrollo.</p>
                    <a href="https://www.facebook.com/alamofm/"><i className="fab fa-facebook"></i> Link al facebook de la radio</a>
                </React.Fragment>
            </Modal>
            <Modal id="juegogj">
                <React.Fragment>
                    <h2>JUEGO PARA LA GAMEJAM DE BLACKTHORNPROD</h2>
                    <p>desarrollo para la primer gamejam del canal BlackThronProd, realizando un juego en 72 horas, desde la idea, la programación, los gráficos y el sonido.</p>
                    <a href="https://grrcodebar.itch.io/gamedevfense"><i className="fab fa-itch-io"></i> Link al itch.io del juego</a>
                </React.Fragment>
            </Modal>
            <Modal id="frentepopular">
                <React.Fragment>
                    <h2>FRENTE POPULAR</h2>
                    <p>Desarrollo de la pagina web, realizando el tema para wordpress del partido político Frente Popular.</p>
                    <a href="http://frentepopular.org.ar/"><i className="fas fa-globe"></i> Link a su web</a>
                </React.Fragment>
            </Modal>
            <Modal id="sucesosnoticias">
                <React.Fragment>
                    <h2>SUCESOS NOTICIAS</h2>
                    <p>Diseño y desarrollo de la web para el periódico digital Sucesos Noticias que funciono entre los años 2010-2014 en la zona de gral roca.</p>
                </React.Fragment>
            </Modal>
            <Modal id="ohmedios">
                <React.Fragment>
                    <h2>OH! MEDIOS</h2>
                    <p>Evolución del proyecto de Sucesos Noticias, convirtiendo el periódico digital en un portal multimedia digital de noticias, en construcción todavía.</p>
                </React.Fragment>
            </Modal>
            <Modal id="cozumel">
                <React.Fragment>
                    <h2>COZUMEL CARILO BALNEARIO</h2>
                    <p>Realización de la programación de la pagina web en React por encargo de un diseñador.</p>
                    <a href="https://gitlab.com/charliegoree/cozumel-theme"><i className="fab fa-gitlab"></i> Link a Gitlab</a>
                    <a href="http://cozumelcarilo.com.ar/"><i className="fas fa-globe"></i> Link a la pagina</a>
                </React.Fragment>
            </Modal>
            <Modal id="photozpot">
                <React.Fragment>
                    <h2>PHOTOZPOT STUDIO</h2>
                    <p>Realización de la programación de la pagina web en React por encargo de un diseñador.</p>
                    <a href="https://gitlab.com/charliegoree/photozpot-theme"><i className="fab fa-gitlab"></i> Link a Gitlab</a>
                    <a href="http://photozpot.com/"><i className="fas fa-globe"></i> Link a la pagina</a>
                </React.Fragment>
            </Modal>
            <Modal id="cyr">
                <React.Fragment>
                    <h2>C&R AMOBLAMIENTOS</h2>
                    <p>Realización de la programación de la pagina web en React por encargo de un diseñador utilizando como HEADLESS CMS a wordpress con su sistema de api rest.</p>
                    <a href="https://gitlab.com/charliegoree/cyr-react-spa"><i className="fab fa-gitlab"></i> Link a Gitlab del frontend</a>
                    <a href="https://gitlab.com/charliegoree/cyr-wp-theme"><i className="fab fa-gitlab"></i> Link a Gitlab del tema para el backend</a>
                </React.Fragment>
            </Modal>
            <Modal id="juegomobile">
                <React.Fragment>
                    <h2>JUEGO MOBILE</h2>
                    <p>Estoy comenzando como proyecto personal la realización de un juego para android, orientado al pvp con un sistema de turnos simultáneos y batallas en linea con REACT y CORDOVA como Front-End y NODEJS/MONGODB como Back-End.</p>
                </React.Fragment>
            </Modal>
            <Modal id="oxigeno">
                <React.Fragment>
                    <h2>OXIGENO CROSSTRAINING</h2>
                    <p>Primer trabajo como cazelab, desarrollo de un sistema de administración para el gimnasio en el cual se administran los clientes, las cuotas y sus pagos, las clases con sus horarios y los turnos a los que se inscriben los clientes en tiempo real, con REACT para el Front-End de los dos clientes, y Firebase como Back-End</p>
                    <a href="https://gitlab.com/charliegoree/appo2admin"><i className="fab fa-gitlab"></i> Link a Gitlab del cliente para el administrador</a>
                    <a href="https://gitlab.com/charliegoree/o2cliente"><i className="fab fa-gitlab"></i> Link a Gitlab del cliente para los clientes</a>
                </React.Fragment>
            </Modal>
            <Modal id="curriculum">
                <React.Fragment>
                    <h2>Este Curriculum</h2>
                    <p>Este curriculum esta completamente desarrollado en React.</p>
                    <a href="https://gitlab.com/charliegoree/curriculum"><i className="fab fa-gitlab"></i> Link a Gitlab del Curriculum Vitae.</a>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    )
}