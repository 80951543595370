import React, {createContext,useState} from 'react';



export const ContextoModales = createContext();

export function ProveedorModales(props:any){
    const [togle,setTogle] = useState<boolean>(false);
    const [id,setId] = useState<string>('');
    function abrirModal(id:string){
        setId(id);
        setTogle(true);
    },
    function cerrarModal(){
        setId('');
        setTogle(false);
    }
    return (
        <ContextoModales.Provider value={{togle,id,abrirModal,cerrarModal}}>
            {props.children}
        </ContextoModales.Provider>
    )
}