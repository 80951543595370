import * as React from 'react';
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useState, useContext} from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
var emailjs = require("../../emailjs.js");

import style from './style.scss';
import foto from '../../assets/contacto.jpg';




export default function Contacto(props:any){
    useEffect(()=>{
        
    })
    return (
        <React.Fragment>
            <div className={style.foto}>
                <img src={foto} alt="Persona"/>
                <div className={style.titulo}>
                    <h1>Contacto</h1>
                    <p>Contactate conmigo por estos medios</p>
                </div>
            </div>
            <div className={style.contenido}>
                <div className={style.carta}>
                    <h1>DATOS</h1>
                    <ul>
                        <li data-tip="Celular"><i className="fas fa-mobile-alt"></i><span>+54 298 4247439</span></li>
                        <li data-tip="Email"><i className="fas fa-envelope"></i><span>grrcodebar@gmail.com</span></li>
                        <li data-tip="Instagram"><i className="fab fa-instagram"></i><span>@Grrcodebar</span></li>
                        <li data-tip="Twitter"><i className="fab fa-twitter"></i><span>@Grrcodebar</span></li>
                        <li data-tip="GitLab"><i className="fab fa-gitlab"></i><span>@Charliegoree</span></li>
                    </ul>
                </div>
                <div className={style.carta}>
                    <h1>EMAIL</h1>
                    <Formulario />
                </div>
            </div>
            <ReactTooltip />
        </React.Fragment>
    )
}

function Formulario(props:any){
    const [nombre,setNombre] = useState<string>('');
    const [asunto,setAsunto] = useState<string>('');
    const [email,setEmail] = useState<string>('');
    const [mensaje,setMensaje] = useState<string>('');
    const [alerta,setAlerta] = useState<string>('');
    const [primera,setPrimera] = useState<boolean>(true);
    
    useEffect(()=>{
        if (primera){
            emailjs.init("user_45ZjGdBVvM28hA0jBXOcV");
            setPrimera(false);
        }
    })
    
    function enviar(e){
        e.preventDefault();
        let template_params = {
            "nombre": nombre,
            "asunto": asunto,
            "email": email,
            "mensaje": mensaje
        }
        let service_id = "default_service";
        let template_id = "template_vb8Joh6f";
        emailjs.send(service_id, template_id, template_params).then((response:any)=>{
            setAlerta('Envio del mensaje exitoso!');
            setNombre('');
            setAsunto('');
            setEmail('');
            setMensaje('');
        },(error:any)=>{
            setAlerta('Error al enviar el mensaje :(');
            setNombre('');
            setAsunto('');
            setEmail('');
            setMensaje('');
        })
    }
    function mensajeAlerta(){
        if (alerta !=""){
            if (alerta == "Envio del mensaje exitoso!"){
                return <p className={style.alertaExitoso}>{alerta}</p>
            } else if (alerta == 'Error al enviar el mensaje :('){
                return <p className={style.alertaFallo}>{alerta}</p>
            }
        }
    }
    return (
        <React.Fragment>
            {
                mensajeAlerta()
            }
        <div className={style.formulario}>
            <h4>Nombre:</h4>
            <input type="text" placeholder="Ej: Pedro Perez" value={nombre} onChange={(e)=>setNombre(e.currentTarget.value)} />
            <h4>Email:</h4>
            <input type="email" placeholder="Ej: persona@email.com" value={email} onChange={(e)=>setEmail(e.currentTarget.value)}/>
            <h4>Asunto:</h4>
            <input type="text" placeholder="Ej: Contacto" value={asunto} onChange={(e)=>setAsunto(e.currentTarget.value)}/>
            <h4>Mensaje:</h4>
            <textarea placeholder="Escribe aqui tu mensaje..." value={mensaje} onChange={(e)=>setMensaje(e.currentTarget.value)}></textarea>
            <button onClick={(e)=>enviar(e)}>Enviar</button>
        </div>
        </React.Fragment>
    )
}